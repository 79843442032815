import React from 'react';
import { ButtonEnum } from '../../shared/lib/types';
import UspBlock, { IUspBlock } from './uniqueSellingPoints/UspBlock';
import { guid } from '../../shared/lib/utils';
import FeaturedContentBlock, {
    IFeaturedContentBlock,
} from './featuredContent/FeaturedContentBlock';

export interface IListBlock {
    heading: string;
    buttonText?: string;
    buttonUrl?: string;
    items: Array<IUspBlock | IFeaturedContentBlock>;
    type: string;
}

const getColClass = (itemsCount: number) => {
    const col = itemsCount >= 4 ? 4 : itemsCount;
    let colClass = `col-sm-6 col-lg-${12 / col}`;

    return colClass;
};

const ListBlock = (props: IListBlock) => (
    <div className="list-block">
        <h2 className="block-heading">{props.heading}</h2>
        <div className="row g-0">
            {props.items && props.items.length
                ? props.items.map((item, i) => {
                      return (
                          <div
                              className={`block block--auto ${
                                  props.type === 'usp-block'
                                      ? 'uspblock'
                                      : 'featuredcontentlinkblock'
                              } ${getColClass(props.items.length)}`}
                              key={guid()}
                          >
                              {props.type === 'usp-block' ? (
                                  <UspBlock {...(item as IUspBlock)} />
                              ) : (
                                  <FeaturedContentBlock
                                      {...(item as IFeaturedContentBlock)}
                                  />
                              )}
                          </div>
                      );
                  })
                : null}
        </div>
        {props.buttonText && props.buttonUrl && (
            <div className="list-block__button-container">
                <a href={props.buttonUrl} className={ButtonEnum.Primary}>
                    {props.buttonText}
                </a>
            </div>
        )}
    </div>
);

export default ListBlock;
