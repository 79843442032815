import { IFeaturedContentBlock } from './FeaturedContentBlock';
import {
    randomString,
    getRandomNumber,
    featuredContentBlockImage,
} from '../../prototypeData';
import { guid } from '../../../shared/lib/utils';
import { IListBlock } from '../ListBlock';

export default {
    heading: 'Featured Content',
    type: 'featured-content-block',
    items: [
        {
            image: featuredContentBlockImage(guid()),
            heading: randomString(getRandomNumber(4, 8)),
            infoText: randomString(getRandomNumber(12, 24)),
            linkText: randomString(4),
            linkUrl: '/',
        } as IFeaturedContentBlock,
        {
            image: featuredContentBlockImage(guid()),
            heading: randomString(getRandomNumber(4, 8)),
            infoText: randomString(getRandomNumber(12, 24)),
            linkText: randomString(4),
            linkUrl: '/',
        } as IFeaturedContentBlock,
        {
            image: featuredContentBlockImage(guid()),
            heading: randomString(getRandomNumber(4, 8)),
            infoText: randomString(getRandomNumber(12, 24)),
            linkText: randomString(4),
            linkUrl: '/',
        } as IFeaturedContentBlock,
        {
            image: featuredContentBlockImage(guid()),
            heading: randomString(getRandomNumber(4, 8)),
            infoText: randomString(getRandomNumber(12, 24)),
            linkText: randomString(4),
            linkUrl: '/',
        } as IFeaturedContentBlock,
    ],
} as IListBlock;
