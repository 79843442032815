import React from 'react';

export interface IFeaturedContentBlock {
    image: string;
    heading: string;
    infoText: string;
    linkText: string;
    linkUrl: string;
}

const FeaturedContentBlock = (props: IFeaturedContentBlock) => (
    <div className="featured-content-block">
        <div className="featured-content-block__image-container">
            <div className="ratio">
                <img src={props.image} alt="" />
            </div>
        </div>
        <div className="featured-content-block__text">
            <h3>{props.heading}</h3>
            <div className="feature-content-block__info-text">
                <p>{props.infoText}</p>
            </div>
            <a href={props.linkUrl}>{props.linkText}</a>
        </div>
    </div>
);

export default FeaturedContentBlock;
