import { IListBlock } from '../ListBlock';
import { IUspBlock } from './UspBlock';
import {
    randomString,
    getRandomNumber,
    UspBlockItemIcon,
} from '../../prototypeData';

export default {
    heading: 'Unique selling points',
    buttonText: randomString(4),
    buttonUrl: '/',
    type: 'usp-block',
    items: [
        {
            heading: randomString(getRandomNumber(3, 8)),
            text: randomString(getRandomNumber(10, 30)),
            iconUrl: UspBlockItemIcon,
        } as IUspBlock,
        {
            heading: randomString(getRandomNumber(3, 8)),
            text: randomString(getRandomNumber(10, 30)),
            iconUrl: UspBlockItemIcon,
        } as IUspBlock,
        {
            heading: randomString(getRandomNumber(3, 8)),
            text: randomString(getRandomNumber(10, 30)),
            iconUrl: UspBlockItemIcon,
        } as IUspBlock,
        {
            heading: randomString(getRandomNumber(3, 8)),
            text: randomString(getRandomNumber(10, 30)),
            iconUrl: UspBlockItemIcon,
        } as IUspBlock,
    ],
} as IListBlock;
