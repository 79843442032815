import React from 'react';
import { startPageBannerImg } from '../prototypeData';

const StartPageBanner = () => (
    <div className="start-page-banner">
        <div className="container container--xxl p-0">
            <div className="row g-0">
                <div className="col-md-6">
                    <div className="start-page-banner__image">
                        <img src={startPageBannerImg} alt="Banner" />
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="start-page-banner__text triangle triangle--md">
                        <div>
                            <h1>
                                <span className="sub-heading">KS Komplett</span>
                                Hjelper regnskapsforetak og regnskapsavdelinger
                                til å kvalitetssikre kontordriften
                            </h1>
                            <a
                                href="/"
                                className="btn btn--secondary btn--inverted"
                            >
                                Bestill abonnement i dag
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default StartPageBanner;
