import React from 'react';
import StartPageBanner from '../components/StartPageBanner';
import ListBlock from '../components/ListBlock';
import UspBlockData from '../components/uniqueSellingPoints/UspBlock.data';
import FeaturedContentBlockData from '../components/featuredContent/FeaturedContentBlock.data';

const StartPage = () => {
    return (
        <>
            <StartPageBanner />
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-10 col-xl-8">
                        <ListBlock {...UspBlockData} />
                        <ListBlock {...FeaturedContentBlockData} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default StartPage;
