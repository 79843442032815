import React from 'react';

export interface IUspBlock {
    iconUrl?: string;
    heading: string;
    text: string;
}

const UspBlock = (props: IUspBlock) => (
    <div className="usp-block">
        {props.iconUrl && <img src={props.iconUrl} alt="" />}
        <h3 className="usp-block__title">{props.heading}</h3>
        <p className="usp-block__text">{props.text}</p>
    </div>
);

export default UspBlock;
